module.exports = {
  getQiniuToken: {
    url: '/api/common/upload/token',
    method: 'GET',
  },
  getConfigToken: {
    url: '/api/frontend/tokenTX/tokenConfigs',
    method: 'GET',
  },
  getConfig: {
    url: '/api/frontend/feeConfig/getConfig',
    method: 'GET',
  },
  getCurrencyExchange: {
    url: '/api/frontend/currencyExchange',
    method: 'GET',
  },
  getVedioDetail: {
    url: '/api/frontend/post/detail',
    method: 'GET',
  },
  getVedioTask: {
    url: '/api/frontend/post/rewardDetail',
    method: 'GET',
  },
  claimVedioReward: {
    url: '/api/frontend/post/chaimPostReward',
    method: 'POST',
  },
  changeLike: {
    url: '/api/frontend/post/like',
    method: 'POST',
  },
  changeCollect: {
    url: '/api/frontend/post/collect',
    method: 'POST',
  },
  changeDislike: {
    url: '/api/frontend/post/dislike',
    method: 'POST',
  },
  getCommonetList: {
    url: '/api/frontend/post/commentList',
    method: 'GET',
  },
  getReplytList: {
    url: '/api/frontend/post/commentReplyList',
    method: 'GET',
  },
  changeCommonetLike: {
    url: '/api/frontend/post/commentLike',
    method: 'POST',
  },
  changeCommonetDislike: {
    url: '/api/frontend/post/commentDislike',
    method: 'POST',
  },
  commentHandler: {
    url: '/api/frontend/post/comment',
    method: 'POST',
  },
  addShareLog: {
    url: '/api/frontend/post/addShareReceiveLog',
    method: 'POST',
  },
  addViewLog: {
    url: '/api/frontend/post/addViewLog',
    method: 'POST',
  },
  statisticsData: {
    url: '/api/frontend/home/dataCount',
    method: 'GET',
  },
  getTask: {
    url: '/api/frontend/task/userTaskDetail',
    method: 'GET',
  },
  buryPoint: {
    url: '/api/user/register',
    method: 'GET',
  },
  getRecommand: {
    url: '/api/frontend/post/recommendPost',
    method: 'GET',
  },
  getRank: {
    url: '/api/frontend/pointsRanking',
    method: 'GET',
  },
  getRecommandList: {
    url: '/api/frontend/post/recommendPostList',
    method: 'GET',
  },
  recommendList: {
    url: '/api/frontend/post/recommendList',
    method: 'GET',
  },
  countryList: {
    url: '/api/common/country-info/list',
    method: 'GET',
  },
  countrcodeyList: {
    url: '/api/common/country/list',
    method: 'GET',
  },
}
