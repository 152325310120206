<template>
  <div
    class="box_wrapper flex flex-jc"
    @click.stop="close"
    :style="[wrapperStyle]"
  >
    <div
      class="box flex flex-col flex-jfs flex-afs"
      :style="[boxSytle]"
      @click.stop=""
    >
      <div class="text1" v-if="popType == 1">Email Registration</div>
      <div class="text1" v-else-if="popType == 3">Forgot Password</div>
      <div class="text1" v-else>
        {{ popType > 0 ? 'Connect with email' : 'Connect with wallet' }}
      </div>
      <div class="box1">
        <div class="grid1" v-if="popType == 0">
          <div class="item" @click="connectMetaMask">
            <img class="item_img" src="@/assets/v2/mask.png" alt="" />
            <div class="text2">Meta Mask</div>
          </div>
          <div class="item" @click="connectokx">
            <img class="item_img" src="@/assets/v2/oKX.png" alt="" />
            <div class="text2">OKX Wallet</div>
          </div>
          <div class="item" @click="connectBitget">
            <img class="item_img" src="@/assets/v2/bitget.png" alt="" />
            <div class="text2">Bitget Wallet</div>
          </div>
          <div class="item" @click="walletHandler">
            <img class="item_img" src="@/assets/v2/wallet.png" alt="" />
            <div class="text2">WalletConnect</div>
          </div>
        </div>
        <div class="box2" v-else-if="popType == 2">
          <div class="box2_text1">Email</div>
          <input
            class="box2_input1"
            type="text"
            v-model="email"
            placeholder="Enter your Email"
          />
          <div class="box2_text1">Password</div>
          <input
            class="box2_input1"
            type="password"
            v-model="password"
            placeholder="Email verification"
          />
          <div class="box2_flex1">
            <div class="box2_flex1_text" @click.stop="popType = 1">
              Email Registration
            </div>
            <div class="box2_flex1_text" @click.stop="popType = 3">
              Forgot Password?
            </div>
          </div>
          <div class="box2_btn" @click="emailLog">Login</div>
        </div>
        <div class="box3" v-if="popType == 1 || popType == 3">
          <div class="box2_text1">Email</div>
          <input
            class="box2_input1"
            type="text"
            v-model="email"
            placeholder="Enter your Email"
          />
          <div class="box2_text1">Code</div>
          <div class="box2_flex2">
            <input
              class="box2_flex2_input"
              type="text"
              v-model="code"
              max="10"
              placeholder="Enter your Code"
            />
            <div
              class="box2_code"
              @click.stop="sendCode()"
              v-if="popType == 1"
              v-loading="sendLoading"
              element-loading-background="rgba(0,0,0,.6)"
            >
              {{ timer ? tick : 'Send Code' }}
            </div>
            <div
              class="box2_code"
              v-if="popType == 3"
              @click.stop="sendCode('forget_password')"
              v-loading="sendLoading"
              element-loading-background="rgba(0,0,0,.6)"
            >
              {{ timer ? tick : 'Send Code' }}
            </div>
          </div>
          <div class="box2_text1">New Password</div>
          <input
            class="box2_input1"
            type="password"
            v-model="password"
            placeholder="Enter your New Passw"
          />
          <div
            class="box2_btn"
            v-if="popType == 1"
            v-loading="regLoading"
            element-loading-background="rgba(0,0,0,.6)"
            @click="emailRegister"
          >
            Register Now
          </div>
          <div
            class="box2_btn"
            v-if="popType == 3"
            v-loading="regLoading"
            element-loading-background="rgba(0,0,0,.6)"
            @click="forgetPassword"
          >
            Sure
          </div>
        </div>
        <div class="or">
          <div class="or_line">OR</div>
        </div>
        <div class="or_items">
          <img
            @click="popType <= 0 ? (popType = 2) : (popType = 0)"
            :src="
              popType <= 0
                ? require('@/assets/v2/Email.png')
                : require('@/assets/v2/loginwallet.png')
            "
            alt=""
          />
          <img @click="logingoogle" src="@/assets/goole.png" alt="" />
          <img
            src="@/assets/Instagramlogin.png"
            ref="telegram"
            alt=""
            @click="loginTelegram"
          />
        </div>
        <div class="text3">
          Linking the wallet means agreeing to the AIcean Terms of Service
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { WalletEthers } from '@/utils/walletconnect2'
import { getAccount, watchAccount, disconnect } from '@wagmi/core'
import walletSign from '@/components/walletSign/index.js'
import { nextTick } from 'vue'
import tonConnectUI from '@/utils/ton'
import { OKXUniversalConnectUI } from '@okxconnect/ui'
export default {
  name: 'Login',
  inject: ['reload'],
  data() {
    return {
      logintype: true,
      activeIndex: -1,
      email: '',
      password: '',
      code: '',
      register_type: 1,
      loadingList: [false, false, false],
      popType: 0,
      typeTimer: null,
      timer: null,
      tick: 60,
      sendLoading: false,
      regLoading: false,
      agreeFlag: true,
      unWatch: null,
      signFlag: false,
      showton: true,
      unsubscribe: null,
      universalUi: null,
    }
  },
  beforeDestroy() {
    console.log('beforeDestroy')
    this.typeTimer && clearTimeout(this.typeTimer)
    this.timer && clearInterval(this.timer)
    this.unWatch && this.unWatch()
    if (this.unsubscribe) {
      this.unsubscribe()
    }
  },
  async mounted() {
    // this.$store.commit('user/INIT_METAOBJ')
    if (!window.TelegramWebviewProxy) {
      this.showton = false
      return
    }
    this.showton = false
    tonConnectUI.uiOptions = {
      twaReturnUrl: 'https://t.me/AiceanBot/AiceanLocal',
      buttonRootId: 'ton-connect',
    }
    tonConnectUI.setConnectRequestParameters({
      state: 'loading',
    })
    await new Promise((resolve, reject) => {
      setTimeout(() => {
        tonConnectUI.setConnectRequestParameters({
          state: 'ready',
          value: {
            tonProof: require('crypto').randomBytes(32).toString('hex'),
          },
        })
      }, 1000)
      resolve()
    })
    this.unsubscribe = tonConnectUI.onStatusChange(async (wallet) => {
      // 更新状态/反应变量以在 UI 中显示更新
      console.log('walletAndwalletInfo', wallet)
      try {
        if (
          wallet &&
          wallet.connectItems?.tonProof &&
          'proof' in wallet.connectItems.tonProof &&
          this.showLog
        ) {
          await this.login({
            type: 6,
            data: {
              wallet_info: wallet,
              invite_code: this.$store.state.common.inviteCode,
              register_type: 3,
            },
          })
          this.$store.commit('user/LOG_STATE', false)
          this.$store.commit('user/SET_TON', false)
          this.$message({
            message: 'Login Success',
            type: 'success',
            showClose: true,
            customClass: 'myMessage',
          })
          this.reload()
        }
      } catch (err) {
        console.log('tonlogin error', err)
      }
    })
    tonConnectUI.connectionRestored.then((restored) => {
      if (restored) {
        console.log('Connection restored. Wallet:', {
          ...tonConnectUI.wallet,
          ...tonConnectUI.walletInfo,
        })
      } else {
        console.log('Connection was not restored.')
      }
    })
    // await tonConnectUI.openModal();
    // window.onTelegramAuth = this.onTelegramAuth
    // script.setAttribute('data-onauth', 'window.onTelegramAuth(user)')
    // this.$refs.telegram.appendChild(script)
  },
  methods: {
    ...mapActions('user', ['login']),
    async connectBitget() {
      if (!window.bitkeep) {
        window.open('https://web3.bitget.com/zh-CN/wallet-download')
      }
      const provider = window.bitkeep.ethereum

      try {
        const account = await provider.request({
          method: 'eth_requestAccounts',
        })
        let message = 'data'
        const signature = await window.bitkeep.ethereum.signPersonalMessage(
          message
        )
        console.log('签名:', signature)
        await this.$store.dispatch('user/login', {
          type: 1,
          data: {
            chain_address: account[0],
            signature: signature,
            message: message,
            invite_code:
              this.$store.state.common.inviteCode ||
              localStorage.getItem('inviteCode'),
          },
        })
        this.$store.commit('user/LOG_STATE', false)
        this.$message({
          message: 'Login Success',
          type: 'success',
          showClose: true,
          customClass: 'myMessage',
        })
        this.signFlag = false
      } catch (error) {
        console.log(error, 'error')
        await provider.disconnect()
      }
    },
    async connectokx() {
      try {
        this.universalUi = await OKXUniversalConnectUI.init({
          dappMetaData: {
            icon: 'https://img.speckies.xyz/default_avatar.png',
            name: 'OKX Connect AICEAN',
          },
          actionsConfiguration: {
            modals: 'all',
            tmaReturnUrl: 'back',
          },
        })
        this.universalUi.disconnect()
        const exampleMessage = 'personal_sign'
        var session = await this.universalUi.openModalAndSign(
          {
            namespaces: {
              eip155: {
                // 请按需组合需要的链id传入，多条链就传入多个
                chains: ['eip155:1'],
                defaultChain: '1',
                rpcMap: {
                  137: 'https://polygon.drpc.org',
                },
              },
            },
            optionalNamespaces: {
              eip155: {
                chains: ['eip155:43114'],
              },
            },
          },
          [
            {
              method: 'personal_sign',
              chainId: 'eip155:1',
              params: [
                `0x${Buffer.from(exampleMessage, 'utf8').toString('hex')}`,
              ],
            },
          ]
        )
        let result = ''
        this.universalUi.on('connect_signResponse', async (signResponse) => {
          if (result != '') {
            return
          }
          // console.log('signResponse', signResponse, signResponse[0]?.result)
          // console.log('session', session)
          if (signResponse[0]?.result) {
            result = signResponse[0]?.result
            await this.$store.dispatch('user/login', {
              type: 1,
              data: {
                chain_address:
                  session.namespaces.eip155.accounts[0].split(':')[2],
                signature: result,
                message: exampleMessage,
                invite_code:
                  this.$store.state.common.inviteCode ||
                  localStorage.getItem('inviteCode'),
              },
            })
            this.$store.commit('user/LOG_STATE', false)
            this.$message({
              message: 'Login Success',
              type: 'success',
              showClose: true,
              customClass: 'myMessage',
            })
            this.signFlag = false
          }
        })

        return
        // if (!window.okxwallet) {
        //   return this.$message({
        //     message: 'Wallet not detected',
        //     type: 'error',
        //     showClose: true,
        //     customClass: 'myMessage',
        //   })
        // }
        // console.log(window.okxwallet)

        // console.log(this.metaObj)
        // let account = this.metaObj.getAccount()
        // // return
        // let data = await this.metaObj.sign(account)
        // console.log(data)
        // await this.$store.dispatch('user/login', {
        //   type: 1,
        //   data: {
        //     chain_address: account,
        //     signature: data.signature,
        //     message: data.message,
        //     invite_code:
        //       this.$store.state.common.inviteCode ||
        //       localStorage.getItem('inviteCode'),
        //   },
        // })
        // this.$store.commit('user/LOG_STATE', false)
        // this.$message({
        //   message: 'Login Success',
        //   type: 'success',
        //   showClose: true,
        //   customClass: 'myMessage',
        // })
        // this.signFlag = false
      } catch (err) {
        this.$message({
          message: err?.reason || err?.data?.message || err?.message || err,
          type: 'error',
          showClose: true,
          customClass: 'myMessage',
        })
        console.error('User rejected the connection request:', err)
      }
    },
    async connectMetaMask() {
      try {
        const MMSDK = new MetaMaskSDK.MetaMaskSDK({
          dappMetadata: {
            name: 'Example Pure JS Dapp',
          },
          // Other options.
        })
        const msg = 'data'
        const accounts = await MMSDK.connect()
        const signResult = await MMSDK?.connectAndSign({
          msg: msg,
        })
        console.log(accounts, 'MMSDK', signResult)
        await this.$store.dispatch('user/login', {
          type: 1,
          data: {
            chain_address: accounts[0],
            signature: signResult,
            message: msg,
            invite_code:
              this.$store.state.common.inviteCode ||
              localStorage.getItem('inviteCode'),
          },
        })
        this.$store.commit('user/LOG_STATE', false)
        this.$message({
          message: 'Login Success',
          type: 'success',
          showClose: true,
          customClass: 'myMessage',
        })
        this.signFlag = false
      } catch (err) {
        // this.$message({
        //   message: err?.reason || err?.data?.message || err?.message || err,
        //   type: 'error',
        //   showClose: true,
        //   customClass: 'myMessage',
        // })
        await disconnect()
        this.$store.commit('user/logout')
        console.error('User rejected the connection request:', err)
      }
    },
    onTelegramAuth(user) {
      console.log(user)
    },
    logingoogle() {
      window.location.replace(
        'https://beta-api.aicean.ai/api/frontend/auth/google'
      )
    },
    async loginTelegram() {
      let oauth = await this.$request({
        path: 'user/getOauthUrl',
        data: { redirect_uri: '*' },
      })
      //这里唯一要做的就是把你机器人参数传入进去（获取机器人token哪里可以看到）
      window.Telegram.Login.auth(
        {
          bot_id: oauth.data.bot_id,
          origin: 'https://www.aicean.ai/',
          request_access: 'write',
          telegram_login: 'AiceanTestbot',
          embed: 1,
        },
        async (data) => {
          console.log(data, '这是回调数据') //这里的data和之前返回的user数据和格式无差异
          if (!data) {
            //失败时你需要做的逻辑
            return
          } else {
            await this.login({
              type: 4,
              data: {
                user: data,
                invite_code:
                  this.inviteCode || localStorage.getItem('inviteCode'),
              },
            })
            this.$store.commit('user/LOG_STATE', false)
            this.$message({
              message: 'Login Success',
              type: 'success',
              showClose: true,
              customClass: 'myMessage',
            })
            console.log(this.$router.path)
            this.reload()
          }

          //电报登录成功你需要做的逻辑（这里我是直接写了一个函数去调用登录成功后的业务逻辑）
          // this.yourCallbackFunction(data)
        }
      )
    },
    async getProvider() {
      const BitKeepProvider = window.bitkeep && window.bitkeep?.ethereum

      await BitKeepProvider.request({ method: 'eth_requestAccounts' })

      BitKeepProvider.removeAllListeners()
      BitKeepProvider.on('accountsChanged', async (accounts) => {
        console.log('accounts changed')
      })
      BitKeepProvider.on('chainChanged', async (chainId) => {
        console.log('chainId changed')
      })

      //MetaMask used
      const MetaMaskProvider = window.ethereum
      //BitKeepProvider.removeAllListeners();
      MetaMaskProvider.request({ method: 'eth_requestAccounts' })
      MetaMaskProvider.removeAllListeners()
      MetaMaskProvider.on('accountsChanged', async (accounts) => {
        console.log('accounts changed')
      })
      MetaMaskProvider.on('chainChanged', async (chainId) => {
        console.log('chainId changed')
      })
    },
    close() {
      // this.$message({message:'测试'})
      this.email = ''
      this.password = ''
      this.code = ''
      this.activeIndex = -1
      this.timer && clearTimeout(this.timer)
      if (this.popType !== 0) {
        if (this.typeTimer) {
          clearTimeout(this.typeTimer)
          this.typeTimer = null
        }
        this.typeTimer = setTimeout(() => {
          this.typeTimer = null
          this.popType = 0
        }, 300)
      }
      nextTick(() => {
        this.$store.commit('user/LOG_STATE', false)
      })
    },
    clickItem(index) {
      this.activeIndex = this.activeIndex == index ? -1 : index
    },
    validateEmail(email) {
      var re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      return re.test(email)
    },
    async emailLog() {
      try {
        if (!this.email) throw 'Please Enter your Email'
        else if (!this.password) throw 'Please Enter Password'
        else if (!this.agreeFlag) throw 'Please Agree Policy'
        if (!this.validateEmail(this.email)) {
          return this.$message({
            message: 'Please enter the correct email address',
            type: 'warning',
            showClose: true,
            customClass: 'myMessage',
          })
        }
        await this.login({
          type: 2,
          data: { email: this.email, password: this.password },
        })
        this.$store.commit('user/LOG_STATE', false)
        this.$message({
          message: 'Login Success',
          type: 'success',
          showClose: true,
          customClass: 'myMessage',
        })
        console.log(this.$router.path)
        this.reload()
        // if( !this.chain ) await this.bindHandler();
      } catch (err) {
        console.log('邮箱登陆失败---', err)
        // this.$message({
        //   message: err,
        //   type: 'error',
        //   showClose: true,
        //   customClass: 'myMessage',
        // })
      }
    },
    async walletHandler() {
      // await this.walletObj.connect()
      if (!this.agreeFlag)
        return this.$message({
          message: 'Please Agree Policy',
          type: 'error',
          showClose: true,
          customClass: 'myMessage',
        })
      const { isConnected } = getAccount()
      if (!isConnected) {
        await WalletEthers.modal.openModal()
        this.unWatch && this.unWatch()
        this.unWatch = watchAccount((account) => {
          console.log('有地址变化---', account)
          // alert('有地址变化---', account)
          if (account.address && account.isConnected) {
            this.loginHandler()
            this.unWatch()
          }
        })
      } else this.loginHandler()
    },
    async loginHandler() {
      try {
        const { address, isConnected } = getAccount()
        if (!isConnected) throw 'please connect wallet'
        this.signFlag = true
        const signature = await this.walletObj.sign(WalletEthers.message)
        await this.$store.dispatch('user/login', {
          type: 1,
          data: {
            chain_address: address,
            signature,
            message: WalletEthers.message,
            invite_code:
              this.$store.state.common.inviteCode ||
              localStorage.getItem('inviteCode'),
          },
        })
        this.$store.commit('user/LOG_STATE', false)
        this.$message({
          message: this.$t('login.LoginSuccess'),
          type: 'success',
          showClose: true,
          customClass: 'myMessage',
        })
        WalletEthers.reload()
      } catch (err) {
        console.log('err-', err)
        this.$message({
          message: err?.reason || err?.data?.message || err?.message || err,
          type: 'error',
          showClose: true,
          customClass: 'myMessage',
        })
        // await disconnect()
        // this.$store.dispatch('user/logout')
      }
      this.signFlag = false
    },
    async sendCode(type = 'register') {
      try {
        if (this.sendLoading || this.timer) return
        if (!this.validateEmail(this.email)) {
          return this.$message({
            message: 'Please enter the correct email address',
            type: 'warning',
            showClose: true,
            customClass: 'myMessage',
          })
        }
        this.sendLoading = true
        // if(!this.email.match(/^([a-zA-Z0-9_.-]+)@([a-zA-Z0-9_-]+).([a-zA-Z0-9_-]+)(.[a-zA-Z0-9_-]+)*$/)) throw '邮箱格式不正确';
        await this.$request({
          path: 'user/sendCode',
          data: {
            email: this.email,
            type,
          },
        })
        this.timer = setInterval(() => {
          if (!this.tick) {
            clearInterval(this.timer)
            this.timer = null
            return (this.tick = 60)
          }
          this.tick--
        }, 1000)
      } catch (err) {
        console.log('发送失败---', err)
        // this.$message({
        //   message: err,
        //   type: 'error',
        //   showClose: true,
        //   customClass: 'myMessage',
        // })
      }
      this.sendLoading = false
    },
    async emailRegister() {
      try {
        if (!this.email) throw 'Please Enter your Email'
        else if (!this.code) throw 'Please Enter Code'
        else if (!this.password) throw 'Please Enter Password'
        if (!this.validateEmail(this.email)) {
          return this.$message({
            message: 'Please enter the correct email address',
            type: 'warning',
            showClose: true,
            customClass: 'myMessage',
          })
        }
        this.regLoading = true
        await this.login({
          type: 3,
          data: {
            email: this.email,
            code: this.code,
            password: this.password,
            invite_code: this.inviteCode || localStorage.getItem('inviteCode'),
            register_type: this.register_type,
          },
        })
        this.$message({
          message: 'Register Success',
          type: 'success',
          showClose: true,
          customClass: 'myMessage',
        })
        this.$store.commit('user/LOG_STATE', false)
        this.reload()
        // if( !this.chain ) await this.bindHandler();
      } catch (err) {
        console.log('注册时失败---', err)
        // this.$message({
        //   message: err,
        //   type: 'error',
        //   showClose: true,
        //   customClass: 'myMessage',
        // })
      }
      this.regLoading = false
    },
    async forgetPassword() {
      try {
        if (!this.email) throw 'Please Enter your Email'
        else if (!this.code) throw 'Please Enter Code'
        else if (!this.password) throw 'Please Enter Password'
        this.regLoading = true
        await this.$request({
          path: 'user/forgetPassword',
          data: {
            email: this.email,
            code: this.code,
            password: this.password,
            invite_code: this.inviteCode || localStorage.getItem('inviteCode'),
          },
        })
        this.$message({
          message: 'Password reset successful',
          type: 'success',
          showClose: true,
          customClass: 'myMessage',
        })
        this.$store.commit('user/LOG_STATE', false)
        this.reload()
        // if( !this.chain ) await this.bindHandler();
      } catch (err) {
        console.log('注册时失败---', err)
        this.$message({
          message: err,
          type: 'error',
          showClose: true,
          customClass: 'myMessage',
        })
      }
      this.regLoading = false
    },
    jumpPolicy() {
      this.$router.push({ name: 'Policy' })
      this.close()
    },
    cancelHandler() {
      disconnect()
      this.close()
    },
    async bindHandler() {
      try {
        const { isConnected } = getAccount()
        if (!isConnected) {
          await WalletEthers.modal.openModal()
          this.unWatch = watchAccount(async (account) => {
            if (account.address && account.isConnected) walletSign.install({})
          })
        } else walletSign.install({})
      } catch (err) {
        console.log('err', err)
        this.$message({
          message:
            err?.reason ||
            err?.data?.message ||
            err?.message ||
            err?.msb ||
            err,
          type: 'error',
          showClose: true,
          customClass: 'myMessage',
        })
        if (this.unWatch) {
          this.unWatch()
          this.unWatch = null
        }
        await disconnect()
      }
    },
  },
  computed: {
    ...mapGetters([
      'inviteCode',
      'disconnect_ton',
      'showLog',
      'metaObj',
      'walletObj',
      'chain',
      'Email',
      'token',
    ]),
    wrapperStyle() {
      return {
        opacity: this.showLog ? 1 : 0,
        backdropFilter: `blur(${this.show ? 14 : 1}px)`,
        PointerEvents: this.show ? 'auto' : 'none',
        zIndex: this.showLog ? 990 : -100,
      }
    },
    boxSytle() {
      return {
        transform: `scale(${this.showLog ? 1 : 0.1})`,
      }
    },
  },
  watch: {
    token(nval, oval) {
      if (!nval && this.universalUi) {
        console.log('退出okx')
        this.universalUi.disconnect()
      }
    },
    disconnect_ton(navl) {
      if (navl) {
        tonConnectUI.disconnect()
      }
    },
    showLog(nval) {
      console.log(!nval, 'showLog', this.unsubscribe)
      if (nval) {
        this.email = ''
        this.password = ''
        this.code = ''
        this.activeIndex = -1
        this.timer && clearTimeout(this.timer)
        this.timer = null
        this.tick = 60
        if (this.popType !== 0) {
          if (this.typeTimer) {
            clearTimeout(this.typeTimer)
            this.typeTimer = null
          }
          this.typeTimer = setTimeout(() => {
            this.typeTimer = null
            this.popType = 0
          }, 300)
        }
      }
      console.log(this.Email != '')
      if (this.Email != '') {
        this.email = this.Email
        this.clickItem(2)
        this.register_type = 2
        this.popType = 1
        nextTick(() => {
          this.sendCode()
        })
        this.$store.commit('common/SET_Email', '')
      } else {
        this.register_type = 1
      }
      if (window.TelegramWebviewProxy) {
        this.register_type = 3
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.box2 {
  &_text1 {
    font-family: SVN-GilroySemiBold;
    font-size: 1.5rem;
    color: #ffffff;
    line-height: 1.92rem;
    margin-bottom: 1.25rem;
    text-align: left;
  }
  &_input1 {
    width: 25.42rem;
    height: 4.58rem;
    border-radius: 1rem;
    border: 0.08rem solid #4c4f65;
    background: transparent;
    box-sizing: border-box;
    padding: 1.5rem 1.67rem 1.42rem;
    margin-bottom: 1.67rem;
    color: #ffffff;
    &::placeholder {
      color: #999999;
      font-size: 1.13rem;
      line-height: 1.67rem;
      font-family: SVN-Gilroy;
    }
    &:focus {
      outline: 0.06rem solid #ffffff;
    }
  }
  &_flex1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &_text {
      cursor: pointer;
      font-size: 1.17rem;
      color: #edbf4f;
      line-height: 1.67rem;
      text-decoration-line: underline;
    }
  }
  &_flex2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &_input {
      width: 15.42rem;
      height: 4.58rem;
      border-radius: 1rem;
      border: 0.08rem solid #4c4f65;
      background: transparent;
      box-sizing: border-box;
      padding: 1.5rem 1.67rem 1.42rem;
      margin-bottom: 1.67rem;
      color: #ffffff;
      &::placeholder {
        color: #999999;
        font-size: 1.13rem;
        line-height: 1.67rem;
        font-family: SVN-Gilroy;
      }
      &:focus {
        outline: 0.06rem solid #ffffff;
      }
    }
  }
  &_code {
    cursor: pointer;
    width: 9.17rem;
    height: 4.58rem;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffbe00;
    border-radius: 1rem;
    font-family: SVN-GilroyMedium;
    font-size: 1.33rem;
    color: #161827;
    line-height: 1.67rem;
    margin-bottom: 1.67rem;
  }
  &_btn {
    cursor: pointer;
    margin-top: 2rem;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25.42rem;
    height: 4.58rem;
    background: #ffbe00;
    border-radius: 1rem;
    font-family: SVN-GilroyMedium;
    font-size: 1.33rem;
    color: #161827;
    line-height: 1.67rem;
  }
}
.box {
  &_wrapper {
    width: 100%;
    height: 100vh;
    overflow: scroll;
    background: rgba(255, 255, 255, 0.06) !important;
    backdrop-filter: blur(0.875rem) !important;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1002;
    transition: all 0.2s ease;
    // pointer-events: none;
  }
}
.box {
  width: 35.42rem;
  // height: 48.08rem;
  background: #2e324d;
  border-radius: 1.5rem;
  border: 0.08rem solid #313342;
  backdrop-filter: blur(10px);
  margin: auto 0;
}
.text1 {
  width: 35.42rem;
  height: 5rem;
  background: #ffbe00;
  border-radius: 1.5rem 1.5rem 0rem 0rem;
  text-align: center;
  font-size: 1.83rem;
  color: #161827;
  line-height: 5rem;
  font-family: SVN-GilroySemiBold;
}
.box1 {
  padding: 3.75rem 5rem 2rem;
}
.grid1 {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
  grid-gap: 1.25rem;
}
.item {
  cursor: pointer;
  width: 12.08rem;
  height: 11.25rem;
  border-radius: 1rem;
  border: 0.08rem solid #4c4f65;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 5;
  &_img {
    width: 4.17rem;
    height: 4.17rem;
    margin-bottom: 1.42rem;
  }
}
.text2 {
  font-size: 1.17rem;
  color: #ffffff;
  line-height: 1.5rem;
  font-family: SVN-GilroySemiBold;
}
.text3 {
  font-size: 1.17rem;
  color: #999999;
  line-height: 1.67rem;
  margin-top: 3rem;
}
.or {
  margin-top: 1rem;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;
  &_line {
    font-size: 1.17rem;
    color: #ffffff;
    font-family: SVN-GilroySemiBold;
    width: 100%;
    &::after {
      position: absolute;
      left: 0;
      bottom: 0.5rem;
      display: block;
      width: 42%;
      content: '';
      height: 0.01rem;
      background: #4c4f65;
    }
    &::before {
      position: absolute;
      display: block;
      right: 0;
      bottom: 0.5rem;
      width: 42%;
      content: '';
      height: 0.01rem;
      background: #4c4f65;
    }
  }
  &_items {
    box-sizing: border-box;
    display: block;
    margin-top: 2rem;
    width: 100%;
    display: flex;
    justify-content: space-around;
    padding: 0 5rem;
    img {
      cursor: pointer;
      width: 3.33rem;
      height: 3.33rem;
    }
  }
}
@media screen and (max-width: 900px) {
  .box2 {
    &_text1 {
      font-family: SVN-GilroySemiBold;
      font-size: 1.5rem;
      color: #ffffff;
      line-height: 1.92rem;
      margin-bottom: 1.25rem;
      text-align: left;
    }
    &_input1 {
      width: 22.08rem;
      height: 4rem;
      border-radius: 0.83rem;
      border: 0.08rem solid #4c4f65;
      background: transparent;
      box-sizing: border-box;
      padding: 1.5rem 1.67rem 1.42rem;
      margin-bottom: 1.67rem;
      color: #ffffff;
      &::placeholder {
        color: #999999;
        font-size: 1.13rem;
        line-height: 1.67rem;
        font-family: SVN-Gilroy;
      }
      &:focus {
        outline: 0.06rem solid #ffffff;
      }
    }
    &_flex1 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      &_text {
        cursor: pointer;
        font-size: 1.17rem;
        color: #edbf4f;
        line-height: 1.67rem;
        text-decoration-line: underline;
      }
    }
    &_flex2 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      &_input {
        width: 13.17rem;
        height: 4rem;
        border-radius: 0.83rem;
        border: 0.08rem solid #4c4f65;
        background: transparent;
        box-sizing: border-box;
        padding: 1.5rem 1.67rem 1.42rem;
        margin-bottom: 1.67rem;
        color: #ffffff;
        &::placeholder {
          color: #999999;
          font-size: 1.13rem;
          line-height: 1.67rem;
          font-family: SVN-Gilroy;
        }
        &:focus {
          outline: 0.06rem solid #ffffff;
        }
      }
    }
    &_code {
      cursor: pointer;
      width: 8.08rem;
      height: 4rem;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #ffbe00;
      border-radius: 0.83rem;
      font-family: SVN-GilroyMedium;
      font-size: 1.33rem;
      color: #161827;
      line-height: 1.67rem;
      margin-bottom: 1.67rem;
    }
    &_btn {
      cursor: pointer;
      margin-top: 2rem;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 22.08rem;
      height: 4rem;
      background: #ffbe00;
      border-radius: 0.83rem;
      font-family: SVN-GilroyMedium;
      font-size: 1.33rem;
      color: #161827;
      line-height: 1.67rem;
    }
  }
  .box {
    width: 26.25rem;
    // height: 39.58rem;
    background: #2e324d;
    border-radius: 1rem;
    border: 0.08rem solid #313342;
    backdrop-filter: blur(10px);
    margin: auto 0;
  }
  .text1 {
    width: 26.25rem;
    height: 4.17rem;
    background: #ffbe00;
    border-radius: 1rem 1rem 0rem 0rem;
    text-align: center;
    font-size: 1.33rem;
    color: #161827;
    line-height: 4.17rem;
    font-family: SVN-GilroySemiBold;
  }
  .box1 {
    padding: 2rem;
  }
  .grid1 {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    grid-gap: 1.25rem;
  }
  .item {
    cursor: pointer;
    width: 10.42rem;
    height: 10.42rem;
    border-radius: 1rem;
    border: 0.08rem solid #4c4f65;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 5;
    &_img {
      width: 4.17rem;
      height: 4.17rem;
      margin-bottom: 1.42rem;
    }
  }
  .text2 {
    font-size: 1.17rem;
    color: #ffffff;
    line-height: 1.42rem;
    font-family: SVN-GilroySemiBold;
  }
  .text3 {
    font-size: 1rem;
    color: #999999;
    line-height: 1.67rem;
    margin-top: 1.5rem;
  }
  .or {
    margin-top: 1rem;
    color: #ffffff;
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: relative;
    &_line {
      font-size: 1.17rem;
      color: #ffffff;
      font-family: SVN-GilroySemiBold;
      width: 100%;
      &::after {
        position: absolute;
        left: 0;
        bottom: 0.5rem;
        display: block;
        width: 42%;
        content: '';
        height: 0.01rem;
        background: #4c4f65;
      }
      &::before {
        position: absolute;
        display: block;
        right: 0;
        bottom: 0.5rem;
        width: 42%;
        content: '';
        height: 0.01rem;
        background: #4c4f65;
      }
    }
    &_items {
      box-sizing: border-box;
      display: block;
      margin-top: 1rem;
      width: 100%;
      display: flex;
      justify-content: space-around;
      padding: 0 5rem;
      img {
        cursor: pointer;
        width: 3.33rem;
        height: 3.33rem;
      }
    }
  }
}
</style>
