import request from '@/api'
import store from '../index.js'
import { MetaEthers } from '@/utils/metamask'
import { disconnect } from '@wagmi/core'
import router from '@/router/index.js'
import { WalletEthers } from '@/utils/walletconnect2'
import tonConnectUI from '@/utils/ton'
const state = {
  Info: {
    id: '',
    avatar: '',
    email: '',
    gender: '',
    invite_code: '',
    invite_num: '',
    nickname: '',
    chain: '',
    spk: '',
    twitter: null,
    facebook: null,
    youtube: null,
    instagram: null,
    is_new_register: 0,
    logType: 0, // 1.metamask 2.walletconnect
  },
  showLog: false,
  showsign: false,
  signinfo: {},
  disconnect_ton: false,
  token: null,
  taskData: {},
  metaObj: null,
  walletObj: null,
  SPK: 0,
  message:
    'Argee to signing message on AICEAN. This request will not trigger a blockchain transaction or cost any gas fees.',
  logState: false,
  shownewuserguid: false,
  shopinfo: {},
  selectaddress: {
    name: '',
    dial_code: 1,
    phone: '',
    detail_address: '',
  },
}

const mutations = {
  SET_SHOWSIGN(state, bol) {
    state.showsign = bol
  },
  SET_SHOPINFO(state, bol) {
    state.shopinfo = bol
  },
  SET_selectaddress(state, bol) {
    state.selectaddress = bol
  },
  SET_SIGNINO(state, data) {
    state.signinfo = data
  },
  SET_TON(state, data) {
    state.disconnect_ton = data
  },
  SET_INFO(state, data) {
    if (!data.chain_address) data.chain_address = ''
    state.Info = data
    localStorage.setItem('userInfo', JSON.stringify(data))
  },
  REMOVE_INFO(state) {
    state.Info = {
      id: '',
      avatar: '',
      email: '',
      gender: '',
      chain_address: '',
      invite_code: '',
      invite_num: '',
      name: '',
      chain: '',
      spk: '',
      twitter: null,
      facebook: null,
      youtube: null,
      instagram: null,
      logType: null,
    }
    localStorage.removeItem('userInfo')
    state.logState = false
  },
  SET_TOKEN(state, data) {
    state.token = data
    localStorage.setItem('token', data)
  },
  REMOVE_TOKEN(state) {
    state.token = null
    localStorage.removeItem('token')
  },
  SET_TASKLIST(state, list) {
    state.taskData = list
  },
  INIT_METAOBJ(state) {
    state.metaObj = new MetaEthers()
  },
  REMOVE_METABOJ(state) {
    state.metaObj = null
  },
  SET_WALLETOBJ(state, reload) {
    state.walletObj = new WalletEthers(reload)
  },
  REMOVE_WALLETOBJ(state) {
    state.walletObj = null
  },
  SET_SPK(state, val) {
    state.SPK = val
  },
  REMOVE_SPK(state) {
    state.SPK = 0
  },
  SET_LOGSTATE(state) {
    state.logState = true
  },
  SET_SHOWNEWUSER(state, bol) {
    state.shownewuserguid = bol
  },
  LOG_STATE(state, bol) {
    state.showLog = bol
  },
}

const actions = {
  async login({ state, commit }, { type, data }) {
    try {
      let path = null
      switch (type) {
        case 1:
          path = 'walletLogin'
          break
        case 2:
          path = 'emailLogin'
          break
        case 3:
          path = 'emailRegister'
          break
        case 4:
          path = 'telegramLogin'
          break
        case 5:
          path = 'googlelogin'
          break
        case 6:
          path = 'tonlogin'
          break
      }
      let res = await request({
        path: `user/${path}`,
        data,
      })
      console.log('登录成功')
      if (type == 3) {
        window.dataLayer.push({ event: 'Web_Header_Logup_click' })
      } else {
        window.dataLayer.push({ event: 'Web_Header_Login_click' })
      }

      commit('SET_INFO', { ...res.data.user, logType: data.type })
      commit('SET_TOKEN', res.data.token)
      setTimeout(() => {
        commit('LOG_STATE', false)
      }, 1000)

      let uRes = await request({
        path: 'user/getInfo',
      })
      // console.log('获取到用户信息', uRes.data)
      commit('SET_INFO', uRes.data)

      let walletRes = await request({
        path: 'user/getWalletDetail',
        data: { currency_key: 'taice' },
      })
      if (
        Number(walletRes.data.is_sign_in_reward) == 1 &&
        window.TelegramWebviewProxy
      ) {
        setTimeout(() => {
          commit('SET_SHOWSIGN', true)
          commit('SET_SIGNINO', { ...walletRes.data })
        }, 1000)
      }
      commit('SET_SPK', walletRes.data?.value || 0)
      // console.log(router.currentRoute.path)

      if (res.data.user.is_new_desc === 0) {
        commit('SET_SIGNINO', {
          ...uRes.data,
          is_new_desc: 1,
        })
        commit('SET_SHOWNEWUSER', true)
      }

      if (
        router.currentRoute.path == '/Started' ||
        router.currentRoute.path == '/started' ||
        router.currentRoute.path.toLowerCase() == '/started'
      ) {
        // store.commit('common/SET_showLogPop', true)
        // router.push({ path: '/Success' })
        // window.location.href = '/Success'
        window.location.replace('/Success')
        // let backlen = history.length
        // window.history.go(-backlen)
        // router.replace({ name: 'Home' })
      }
    } catch (err) {
      console.log('登录失败', err)
      throw err
    }
  },
  async logout({ state, commit }) {
    console.log('登出----')
    await disconnect()
    commit('REMOVE_INFO')
    commit('REMOVE_TOKEN')
    commit('REMOVE_SPK')
    if (window.TelegramWebviewProxy) {
      await tonConnectUI.disconnect()
      commit('SET_TON', true)
    }
    let deviceID = localStorage.getItem('SPK_deviceId')
    localStorage.clear()
    localStorage.setItem('SPK_deviceId', deviceID)
    state.walletObj?.destory()
    let backlen = history.length
    window.history.go(-backlen)
    router.replace({ name: 'Home' })
  },
  async updateInfo({ commit }) {
    try {
      const { data } = await request({
        path: 'user/getInfo',
      })
      commit('SET_INFO', data)
    } catch (err) {
      throw err
    }
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
