import request from '@/api'
import { _isMobile } from '@/utils/common'
import store from '../index.js'
import moment from 'moment'
const state = {
  windowHeight: 0,
  windowWidth: 0,
  isMoblie: false,
  showLog: false,
  showLogPop: false,
  showinvitation: false,
  invitationid: null,
  hasPop: false,
  Email: '',
  inviteCode: null,
  SearchKey: '',
  deviceID: null,
  keepRoutes: [],
}

const mutations = {
  SET_SIZE(state, data) {
    // console.log('设置尺寸', data)
    state.windowHeight = data.height
    state.windowWidth = data.width
    if (data.width <= 900) {
      document.documentElement.style.fontSize = data.width / (375 / 12) + 'px'
    } else {
      document.documentElement.style.fontSize = null
    }

    state.isMoblie = Boolean(_isMobile())
  },
  ADD_KEEP_ROUTES(state, name) {
    if (state.keepRoutes.find((item) => item == name)) return
    state.keepRoutes.push(name)
  },
  REMOVE_KEEP_ROUTES(state, name) {
    let index = state.keepRoutes.findIndex((item) => item == name)
    if (!~index) return console.log('没有找到路由')
    state.keepRoutes.splice(index, 1)
  },
  CLEAR_KEEP_ROUTES(state) {
    state.keepRoutes = []
  },
  SET_Email(state, value) {
    state.Email = value
  },
  REMOVE_INVITATION_ID(state, bol) {
    state.invitationid = null
    state.showinvitation = false
  },
  SET_INVITATION(state, bol) {
    state.showinvitation = bol.show
    if (bol.id) {
      state.invitationid = bol.id
    }
  },
  LOG_STATE(state, bol) {
    state.showLog = bol
  },
  SET_showLogPop(state, bol) {
    state.showLogPop = bol
  },
  SET_DEVICE_ID(state, id) {
    // console.log('获取到的id', id)
    state.deviceID = id
  },
  SET_SEARCHKEY(bol) {
    state.SearchKey = bol
  },
}

export default {
  namespaced: true,
  state,
  mutations,
}
