<template>
  <transition name="fade">
    <div
      v-show="showFlag"
      class="box_wrapper flex flex-jc flex-ac"
      @click.stop
      @touchmove.stop
    >
      <div class="box flex flex-col flex-jfs flex-afs">
        <img
          class="box_close"
          src="@/assets/close.svg"
          @click="cancelHandler"
        />
        <img class="box_logo" src="@/assets/logLogo.png" />

        <div
          class="box_item_wrapper box_item flex flex-jfs flex-ac"
          @click="walletHandler(0)"
        >
          WalletConnect
        </div>

        <!-- <div 
                    class="box_item_wrapper box_item flex flex-jfs flex-ac"  
                    @click="walletHandler(1)" 
                >
                    WalletConnect
                </div> -->
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      cacheDom: null,
      showFlag: false,
      timer: null,
      resolve: null,
      reject: null,
    }
  },
  created() {
    this.cacheDom = document.querySelectorAll('.web3modal-provider-wrapper')
  },
  methods: {
    async walletHandler(index) {
      await this.walletObj.connect()
      this.resolve('confirm')
      this.hide()
    },
    cancelHandler() {
      this.reject('cancel')
      this.hide()
    },
    show() {
      this.showFlag = true
      return new Promise((res, rej) => {
        this.resolve = res
        this.reject = rej
      })
    },
    hide() {
      this.showFlag = false
      this.timer = setTimeout(() => {
        this.timer = null
        document.body.removeChild(this.$el)
        this.$destroy()
      }, 350)
    },
    beforeDestroy() {
      this.timer && clearTimeout(this.timer)
    },
  },
  computed: {
    ...mapGetters(['walletObj']),
  },
}
</script>

<style lang="scss" scoped>
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s;
}
.box {
  width: 30.63rem;
  min-height: 15.63rem;
  box-sizing: border-box;
  padding: 2.03rem {
    top: 2.81rem;
    bottom: 1.5rem;
  }
  background: linear-gradient(
    135deg,
    transparent 0%,
    transparent 1%,
    rgba(0, 0, 0, 0.7) 1%,
    rgba(0, 0, 0, 0.7) 97.5%,
    transparent 97.5%
  );
  position: relative;
  // transition: all .35s ease;
  &::before {
    content: '';
    position: absolute;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 2rem;
    height: 2rem;
    background: no-repeat center/100% url(@/assets/boxTop.svg);
  }

  &::after {
    content: '';
    position: absolute;
    z-index: 1000;
    bottom: 0;
    right: 0;
    width: 1.6rem;
    height: 1.6rem;
    background: no-repeat center/100% url(@/assets/boxBottom.svg);
  }

  &_wrapper {
    width: 100%;
    height: 100vh;
    background: rgba(255, 255, 255, 0.06) !important;
    backdrop-filter: blur(14px) !important;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1002;
    // transition: all .35s ease;
    // pointer-events: none;
  }

  &_close {
    height: 2.25rem;
    width: 2.25rem;
    position: absolute;
    right: 1rem;
    top: 1rem;
    cursor: pointer;
  }

  &_logo {
    width: 9.13rem;
    height: 2rem;
    margin: 0 {
      bottom: 1.25rem;
    }
  }

  &_item {
    width: 26.56rem;
    height: 3.94rem;
    color: #ffffff;
    font-size: 1.25rem;
    line-height: 1.56rem;
    box-sizing: border-box;
    padding: 1.19rem 0;
    padding-left: 4.56rem;
    margin-bottom: 1rem;
    // &:nth-of-type(1)  {
    //     background:no-repeat center/100% url(@/assets/metamask.png);
    // }

    &:nth-of-type(1) {
      background: no-repeat center/100% url(@/assets/walletconnect.png);
    }
  }
}
</style>
